<template>
  <div class="m-3">
    <div class="card-body pt-0">
      <div>
        <div class="row justify-content-center">
          <div class="col-12">
            <validation-observer
              ref="userChangePassword"
            >
            <b-form @submit="onSubmit">
              <b-form-group
                label-for="register-password"
                label="Contraseña"
                description="puedes usar caracteres especiales, letras y numeros"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:5"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      size="sm"
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password confirmation -->
              <b-form-group
                label-for="register-password-confirmation"
                label="Confirmar Contraseña"
                description="puedes usar caracteres especiales, letras y numeros"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar Contraseña"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    label="Repetir Contraseña"
                    description="puedes usar caracteres especiales, letras y numeros"
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      size="sm"
                      v-model="c_password"
                      class="form-control-merge"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      name="register-password-confirmation"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
            </validation-observer>
            <div v-if="generatePass">
              <span>Contraseña generada {{generatePass}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 float-right button-primary"
            @click="onSubmit"
          >ACTUALIZAR</b-button>
          <b-button size="sm" class="mr-1 float-right" variant="primary" @click="onGenerate">GENERAR</b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import UserService from "../../../services/UserService";
import { BForm, BFormGroup, BInputGroup, BFormInput, BButton } from 'bootstrap-vue'
import { required } from "@validations";

export default {
  name: "UserChangePassword",
  components:{
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    user: Object
  },
  data() {
    return {
      required,
      c_password: null,
      password: null,
      generatePass: null
    };
  },
  methods: {
    onGenerate() {
      this.generatePass = Math.random()
        .toString(36)
        .slice(-10);
      this.password = this.generatePass;
      this.c_password = this.generatePass;
    },
    onSubmit(event) {
      event.preventDefault();
      this.$refs.userChangePassword.validate().then(valid => {
        if (valid) {
          UserService.newPassword({
            user_id: this.user.id,
            password: this.password
          })
            .then(() => {
              this.$emit("onClose", { change: true });
            })
            .catch(error => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach(key => {
                  response.data.errors[key].forEach(error => {
                    this.veeErrors.add({
                      field: key,
                      msg: error
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    }
  }
};
</script> 

<style lang="scss" scoped>
</style>
