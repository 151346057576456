import axios from 'axios';

export default class CustomerService {

  /**
   * @description login
   * @param { username | login, password } values
   */
  static index() {
    const params = { all: true };
    return axios.get(process.env.VUE_APP_API + '/customers', { params });
  }

  /**
   * @description store data
   * @param data { location_id, street, height,  postal_code } values
   */
  static store(data) {
    return axios.post(process.env.VUE_APP_API + '/customers', data);
  }

  /**
   * @description update data
   * @param data { location_id, street, height,  postal_code } values
   */
  static update(data, customer_id) {
    return axios.put(process.env.VUE_APP_API + '/customers/' + customer_id, data);
  }

  /**
   * @description store data
   * @param data { location_id, street, height,  postal_code } values
   */
  static save(values, customer_id) {
    if (values.keopsUser) {
      values.keops_id = values.keopsUser.keops_id
    }
    return customer_id ? this.update(values, customer_id) : this.store(values);
  }

  /**
   * @description delete a element
   * @param { id } id point
   */
  static destroy({ id }) {
    return axios.delete(process.env.VUE_APP_API + '/customers/' + id);
  }

  /**
   * @description update a element
   * @param { id } id point
   * @param values { location_id, street, height,  postal_code } update values
   */
  static updated(id, values) {
    return axios.put(process.env.VUE_APP_API + '/customers/' + id, values);
  }

  /**
   * @description get a element by id
   * @param { id } id point
   */
  static show(id) {
    return axios.get(process.env.VUE_APP_API + '/customers/' + id);
  }

  /**
   * @description get a element by id
   * @param { id } id point
   */
  static prices() {
    return axios.get(process.env.VUE_APP_API + '/customers/prices');
  }

  /*
   * @description update_password
   * @param { password,c_password  } values
   */
  static update_password(values) {
    return axios.post(process.env.VUE_APP_API + '/customers/update_password', values);
  }

  /*
   * @description update_password
   * @param customer values
   */
  static enabled(customer, value) {
    return axios.put(process.env.VUE_APP_API + '/customers/' + customer.id + '/enabled', value);
  }

  /*
   * @description update_password
   * @param { password,c_password  } values
   */
  static disabled(customer, value) {
    return axios.put(process.env.VUE_APP_API + '/customers/' + customer.id + '/disabled', value);
  }

  /**
   * 
   *
   */
  static generateToken(values) {
    return axios.post(process.env.VUE_APP_API + '/customers/token', { channel: values });
  }

  /**
   * 
   * @param {*} user_id 
   */
  static reverify(user_id) {
    return axios.post(process.env.VUE_APP_API + '/reverify', { user_id });
  }

  /**
   * 
   *
   */
  static destroy_token(id) {
    return axios.delete(process.env.VUE_APP_API + '/oauth/personal-access-tokens/' + id);
  }

  /**
  * @description 
  */
  static export(params = {}) {
    return axios.get(process.env.VUE_APP_API + '/customers/export', {
      params: params,
      responseType: 'blob'
    });
  }

  /**
   * 
   */
  static registerUpdateKeops({ id }) {
    return axios.put(process.env.VUE_APP_API + '/customers/' + id + '/register-or-update-keops');
  }
}
