<template>
  <div class="m-3">
    <div class="card-body card-modal">
      <div style="height: 100%">
        <loading :active.sync="isLoading" :is-full-page="false"></loading>
        <h3 v-if="edit" class="title mt-2">EDITAR CLIENTE</h3>
        <h3 v-else class="title mt-2">AGREGAR CLIENTE</h3>
        <div class="row justify-content-center">
          <div class="col-12">
            <validation-observer ref="addCustomerForm">
              <b-form @submit="onSubmit">
                <!-- email -->
                <b-form-group label="Email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      autocomplete="off"
                      v-model="customer.email"
                      name="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                      :disabled="edit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group v-if="!edit" label="Contraseña">
                  <validation-provider
                    #default="{ errors }"
                    name="Contraseña"
                    vid="password"
                    rules="required|min:5"
                  >
                    <b-input-group>
                      <b-form-input
                        size="sm"
                        v-model="customer.password"
                        class="form-control"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        name="password"
                        placeholder="Contraseña"
                        autocomplete="off"
                        ref="password"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password confirmation -->
                <b-form-group label="Repetir Contraseña" v-if="!edit">
                  <validation-provider
                    #default="{ errors }"
                    name="Confirmar Contraseña"
                    rules="required|min:5|confirmed:password"
                  >
                    <b-input-group>
                      <b-form-input
                        size="sm"
                        v-model="customer.c_password"
                        class="form-control"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        name="c_password"
                        placeholder="Confirmar contraseña"
                        autocomplete="off"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- CUIT / DNI -->
                <b-form-group label="CUIT / DNI">
                  <validation-provider
                    #default="{ errors }"
                    name="CUIT / DNI"
                    :rules="{
                      required: true,
                      integer: true,
                      min: customer.type_document === 'dni' ? 8 : 11,
                      max: customer.type_document === 'dni' ? 8 : 11,
                    }"
                  >
                    <b-form-input
                      size="sm"
                      autocomplete="dni"
                      placeholder="00000000"
                      v-model="customer.dni"
                      name="dni"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-form-group class="my-1">
                      <b-form-radio-group
                        v-model="customer.type_document"
                        @input="onTypeDocument"
                        :options="documents"
                        name="type_document"
                      ></b-form-radio-group>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>

                <!-- Marca -->
                <b-form-group label="Marca">
                  <validation-provider
                    #default="{ errors }"
                    name="Marca"
                    rules="required|max:255"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      v-model="customer.brand"
                      name="brand"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Nombre -->
                <b-form-group label="Nombre">
                  <validation-provider
                    #default="{ errors }"
                    name="Nombre"
                    rules="required|max:255"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      v-model="customer.name"
                      name="name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Apellido -->
                <b-form-group label="Apellido">
                  <validation-provider
                    #default="{ errors }"
                    name="Apellido"
                    rules="max:255"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      v-model="customer.last_name"
                      name="last_name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Telefono -->
                <b-form-group label="Telefono">
                  <validation-provider
                    #default="{ errors }"
                    name="Telefono"
                    rules="max:20"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      v-model="customer.phone"
                      name="phone"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <h3 class="title mt-2">DIRECCIÓN DEL CLIENTE</h3>

                <!-- Provincia -->
                <b-form-group label="Provincia">
                  <validation-provider
                    #default="{ errors }"
                    name="Provincia"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="province"
                      v-model="customer.direction.province_id"
                      :options="provinces"
                      @input="onProvinces"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Localidad -->
                <b-form-group label="Localidad">
                  <validation-provider
                    #default="{ errors }"
                    name="Localidad"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="province"
                      v-model="customer.direction.location_id"
                      :options="locations"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Calle -->
                <b-form-group label="Calle">
                  <validation-provider
                    #default="{ errors }"
                    name="Calle"
                    rules="required|max:250"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      max="250"
                      v-model="customer.direction.street"
                      name="street"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Altura -->
                <b-form-group label="Altura">
                  <validation-provider
                    #default="{ errors }"
                    name="Altura"
                    rules="required|max:15"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      max="15"
                      v-model="customer.direction.height"
                      name="height"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Codigo Postal -->
                <b-form-group label="Codigo Postal">
                  <validation-provider
                    #default="{ errors }"
                    name="Codigo Postal"
                    rules="required|max:30"
                  >
                    <b-form-input
                      size="sm"
                      type="text"
                      max="30"
                      v-model="customer.direction.postal_code"
                      name="postal_code"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Nota -->
                <b-form-group label="Nota">
                  <validation-provider
                    #default="{ errors }"
                    name="Nota"
                    rules="max:400"
                  >
                    <b-form-textarea
                      size="sm"
                      xlength="500"
                      v-model="customer.direction.note"
                      name="note"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <h3 class="title mt-2">DATOS DE ENVIO</h3>

                <!-- Tipo Cobro -->
                <b-form-group label="Tipo Cobro" v-if="isAdmin">
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo Cobro"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="type"
                      v-model="customer.type"
                      :options="typeCustomer"
                      @change="changeTypePay"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Descuento/Incremento -->
                <b-form-group label="Descuento/Incremento">
                  <validation-provider
                    #default="{ errors }"
                    name="Descuento/Incremento"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="price"
                      v-model="customer.preference_price_id"
                      label="text"
                      :options="prices"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> 
                <!-- Servicio de Colecta -->
                <b-form-group label="Servicio de Colecta">
                  <validation-provider
                    #default="{ errors }"
                    name="Servicio de Colecta"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="collection"
                      v-model="customer.collection"
                      text="text"
                      :options="collections"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Punto de Dropoff -->
                <b-form-group label="Punto de Dropoff">
                  <validation-provider
                    #default="{ errors }"
                    name="Punto de Dropoff"
                    rules="required"
                  >
                    <v-select
                      size="sm"
                      name="point"
                      v-model="customer.point"
                      label="fullText"
                      :options="points"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template slot="option" slot-scope="option">
                        <div class="p-1 m-1 border-primary">
                          <div>
                            <b>Provincia:</b>
                            {{ option.province }}
                          </div>
                          <div>
                            <b>Localidad:</b>
                            {{ option.location }}
                          </div>
                          <div>
                            <b>Calle:</b>
                            {{ option.street }}
                          </div>
                          <div>
                            <b>Altura:</b>
                            {{ option.height }}
                          </div>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <div
                    v-if="
                      customer.point &&
                      customer.point.longitude &&
                      customer.point.longitude
                    "
                  >
                    <p class="mt-2">
                      <a :href="url" target="_blank" class="pl-2">
                        <feather-icon size="20" icon="MapIcon" class="mr-50" />
                        Ver punto en Google
                      </a>
                    </p>
                  </div>
                </b-form-group>

                <!-- Servicio de Entrega -->
                <b-form-group label="Servicio de entrega">
                  <validation-provider
                    #default="{ errors }"
                    name="Servicio de entrega"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="retirement"
                      v-model="customer.retirement"
                      text="text"
                      :options="retirements"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Canal de venta -->
                <b-form-group label="Canal de venta">
                  <validation-provider
                    #default="{ errors }"
                    name="Canal de venta"
                    rules="required"
                  >
                    <multiselect
                      size="sm"
                      name="channel"
                      v-model="customer.channels"
                      text="name"
                      :multiple="true"
                      label="name"
                      :options="channels"
                      :state="errors.length > 0 ? false : null"
                    ></multiselect>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Nota -->
                <b-form-group label="Nota">
                  <validation-provider
                    #default="{ errors }"
                    name="Nota"
                    rules="max:400"
                  >
                    <b-form-textarea
                      size="sm"
                      xlength="500"
                      v-model="customer.notes"
                      name="notes"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="HORA INCIAL DE COLECTA: "
                  v-if="
                    customer.type === 'post-pago' ||
                    customer.collection == 'client'
                  "
                >
                  <b-form-timepicker
                    v-model="customer.collection_start_time"
                    :hour12="false"
                  ></b-form-timepicker>
                </b-form-group>

                <b-form-group
                  label="HORA FINAL DE COLECTA: "
                  v-if="
                    customer.type === 'post-pago' ||
                    customer.collection == 'client'
                  "
                >
                  <b-form-timepicker
                    v-model="customer.collection_end_time"
                    :hour12="false"
                  ></b-form-timepicker>
                </b-form-group>

                <h3 class="title my-2">ZONAS DE ENVÍO</h3>

                <b-form-group>
                  <b-form-checkbox-group
                    v-model="customer.selected"
                    :options="options"
                  ></b-form-checkbox-group>
                </b-form-group>

                <h3 class="title my-2">SEGUROS PARA TUS ENVIOS</h3>

                <!-- Zona para seguros-->
                <b-form-group
                  label="Seguros para tu envíos"
                  description="Selecciona las áreas en las que deseas asegurar tus envios"
                >
                  <b-input-group>
                    <!-- <b-input-group-prepend></b-input-group-prepend> -->
                    <validation-provider name="Zona para seguros">
                      <b-form-checkbox-group
                        v-model="customer.is_secured"
                        :options="optionsSecured"
                      ></b-form-checkbox-group>
                    </validation-provider>
                  </b-input-group>
                </b-form-group>

                <h3 class="title">Datos de Cobro</h3>

                <!-- Keops Use-->
                <b-form-group label="Límite de Crédito">
                  <validation-provider
                    #default="{ errors }"
                    name="Límite de Crédito"
                    rules="max:30"
                  >
                    <b-form-input
                      type="number"
                      size="sm"
                      max="13"
                      v-model="customer.credit_limit"
                      name="credit_limit"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Keops Use-->
                <b-form-group label="Keops User">
                  <validation-provider
                    #default="{ errors }"
                    name="Keops User"
                    rules="max:30"
                  >
                    <b-form-input
                      size="sm"
                      max="30"
                      v-model="customer.keopsUser.keops_id"
                      name="keops_id"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Condición IVA -->
                <b-form-group label="Condición IVA">
                  <validation-provider
                    #default="{ errors }"
                    name="Condición IVA"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="vat_condition"
                      v-model="customer.vat_condition"
                      @input="onVatCondition"
                      :options="vat_conditions"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Tipo de Comprobante -->
                <b-form-group label="Tipo de Comprobante">
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de Comprobante"
                    rules="required"
                  >
                    <b-form-select
                      size="sm"
                      name="voucher_type"
                      v-model="customer.voucher_type"
                      :options="voucher_types"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onSubmit"
            >Guardar</b-button
          >
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="outline-primary"
            @click="close"
            >Cancelar</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import ChannelService from "@/services/ChannelService";
import LocationService from "@/services/LocationService";
import OperationService from "@/services/OperationService";

export default {
  name: "CustomerEdit",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    onClose: Object,
    onSave: Object,
    edit: {
      type: Boolean,
      default: false,
    },
    customer_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      required,
      isAdmin: UserService.isAdmin(),
      customer: {
        type_document: "dni",
        email: null,
        name: null,
        password: null,
        c_password: null,
        dni: null,
        collection: null,
        preference_price_id: null,
        retirement: null,
        phone: null,
        IDRemitente: null,
        channels: [],
        type: "pre-pago",
        direction: {},
        keopsUser: {},
        vat_condition: "Responsable Inscripto",
        voucher_type: "Factura A",
        selected: [], // Must be an array reference!
      },
      points: [],
      prices: [],
      provinces: [],
      locations: [],
      channels: [],
      isLoading: false,
      collections: [
        { value: "client", text: "Cliente" },
        { value: "deposit", text: "Drop-Off" },
      ],
      retirements: [
        { value: "client", text: "Domicilio" },
        { value: "point", text: "Punto de retiro" },
        { value: "client & point", text: "Domicilio y punto de retiro" },
      ],
      vat_conditions: [
        { value: "Responsable Inscripto", text: "Responsable Inscripto" },
        { value: "Monotributista", text: "Monotributista" },
        { value: "Consumidor Final", text: "Consumidor Final" },
        { value: "Exento 0%", text: "Exento 0%" },
        { value: "No gravado", text: "No gravado" },
        { value: "Otro", text: "Otro" },
      ],
      voucher_types: [],
      typeCustomer: [
        { value: "pre-pago", text: "Pre-pago" },
        { value: "post-pago", text: "Post-pago" },
      ],
      documents: [
        { text: "DNI", value: "dni" },
        { text: "CUIT", value: "cuit" },
      ],
      options: [
        { text: "CP de Capital Federal", value: "CABA" },
        { text: "CP de GBA", value: "GBA" },
        { text: "CP de La Plata", value: "PLATA" },
        { text: "CP del Interior", value: "INTERIOR" },
      ],
      optionsSecured: [
        {
          text: "Capital Federal",
          value: "CABA",
        },
        {
          text: "GBA",
          value: "GBA",
        },
        {
          text: "La Plata",
          value: "PLATA",
        },
        {
          text: "Interior del país",
          value: "INTERIOR",
        },
      ],
    };
  },
  beforeDestroy() {
    if (this.source) {
      this.source.source.cancel("Operation canceled by the user.");
    }
  },
  methods: {
    onTypeDocument() {
      this.voucher_types = [];
      if (this.customer && this.customer.type_document === "dni") {
        this.vat_conditions = [
          { value: "Consumidor Final", text: "Consumidor Final" },
        ];
      } else {
        this.vat_conditions = [
          { value: "Responsable Inscripto", text: "Responsable Inscripto" },
          { value: "Monotributista", text: "Monotributista" },
          { value: "Exento 0%", text: "Exento 0%" },
          { value: "No gravado", text: "No gravado" },
          { value: "Otro", text: "Otro" },
        ];
      }
    },
    onVatCondition() {
      if (
        this.customer &&
        (this.customer.vat_condition === "Consumidor Final" ||
          this.customer.vat_condition === "Exento 0%")
      ) {
        this.voucher_types = [{ value: "Factura B", text: "Factura B" }];
        return;
      }

      if (
        this.customer &&
        (this.customer.vat_condition === "Responsable Inscripto" ||
          this.customer.vat_condition === "Monotributista")
      ) {
        this.voucher_types = [{ value: "Factura A", text: "Factura A" }];
        return;
      }

      this.voucher_types = [
        { value: "Factura A", text: "Factura A" },
        { value: "Factura B", text: "Factura B" },
      ];
    },
    /**
     *
     */
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    /**
     *
     */
    close() {
      this.$emit("close");
      this.onClose.handler();
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.addCustomerForm.validate().then((valid) => {
        if (valid) {
          const data = Object.assign({}, this.customer);
          if (this.customer.point) {
            data.point_id = this.customer.point.id;
          }
          data.channels = this.customer.channels.map((d) => d.id);
          if (this.customer.shipping_areas) {
            data.shipping_areas = this.customer.shipping_areas
              .filter((d) => this.customer.selected.includes(d.zone))
              .map((d) => d.id);
          }

          if (this.customer.shipping_areas) {
            data.is_secured = this.customer.shipping_areas
              .filter((d) => this.customer.is_secured.includes(d.zone))
              .map((d) => d.id);
          }
          CustomerService.save(data, this.customer_id)
            .then(({ data }) => {
              this.$emit("close");
              this.onSave.handler(data);
              // AlertService.info();
            })
            .catch((error) => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach((key) => {
                  response.data.errors[key].forEach((error) => {
                    this.veeErrors.add({
                      field: key,
                      msg: error,
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },

    /**
     *
     */
    fetchProvinces() {
      LocationService.provinces().then(({ data }) => {
        this.provinces = data.data;
      });
    },

    /**
     *
     */
    async fetchPrices() {
      const { data } = await CustomerService.prices();
      this.prices = data.data;
    },

    /**
     *
     */
    async fetchPoints() {
      try {
        const data = await OperationService.points({ dropoff: true });
        this.points = data.data.data.map((item) => {
          item.value = item.id;
          item.fullText = [
            item.province,
            item.location,
            item.street,
            item.height,
          ]
            .filter((i) => i)
            .join(", ");
          return item;
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     *
     */
    fetchLocations() {
      if (this.customer.direction) {
        LocationService.locations({
          province_id: this.customer.direction.province_id,
        }).then(({ data }) => {
          this.locations = data.data;
        });
      }
    },

    /**
     *
     */
    fetchChannels() {
      ChannelService.channels().then(({ data }) => {
        this.channels = data.data;
      });
    },

    /**
     *
     */
    async loadData() {
      const response = await CustomerService.show(this.customer_id);
      const { data } = response;
      this.customer = data.data;
      this.customer.selected = this.customer.shipping_areas
        .filter((d) => {
          if (d.is_active) return d.zone;
        })
        .map((d) => d.zone);
      this.customer.is_secured = this.customer.shipping_areas
        .filter((d) => {
          if (d.is_secured) return d.zone;
        })
        .map((d) => d.zone);
      if (!this.customer.type_document) {
        this.customer.type_document = "dni";
      }
      if (!this.customer.direction) {
        this.customer.direction = {};
      }

      this.onTypeDocument();
      this.onVatCondition();

      if (this.customer.point) {
        this.customer.point.fullText = [
          this.customer.point.location.province.province,
          this.customer.point.location.location,
          this.customer.point.street,
          this.customer.point.height,
        ]
          .filter((i) => i)
          .join(", ");
      }
    },

    /**
     *
     */
    changeTypePay() {
      if (this.customer.type === "pre-pago") {
        this.customer.collection = "deposit";
        const price = this.prices.find((d) => d.is_prepago);
        if (price) {
          this.customer.preference_price_id = price.value;
        }
      }
    },

    /**
     *
     */
    onProvinces() {
      this.fetchLocations();
      this.fetchPoints();
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      await this.fetchPrices();
      await this.fetchProvinces();
      await this.fetchChannels();
      if (this.edit) {
        await this.loadData();
        await this.onVatCondition();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  },
  computed: {
    url: function () {
      return (
        "https://www.google.com/maps?q=" +
        this.customer.point.latitude +
        "," +
        this.customer.point.longitude
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
