import axios from 'axios';

export default class ChannelService {

    /**
     * @description get provinces
     */
    static channels() {
        return axios.get(process.env.VUE_APP_API + '/channels');
    }

}
