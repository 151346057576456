var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-3"},[_c('div',{staticClass:"card-body card-modal"},[_c('div',{staticStyle:{"height":"100%"}},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),(_vm.edit)?_c('h3',{staticClass:"title mt-2"},[_vm._v("EDITAR CLIENTE")]):_c('h3',{staticClass:"title mt-2"},[_vm._v("AGREGAR CLIENTE")]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('validation-observer',{ref:"addCustomerForm"},[_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","autocomplete":"off","name":"email","state":errors.length > 0 ? false : null,"placeholder":"Email","disabled":_vm.edit},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.edit)?_c('b-form-group',{attrs:{"label":"Contraseña"}},[_c('validation-provider',{attrs:{"name":"Contraseña","vid":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{ref:"password",staticClass:"form-control",attrs:{"size":"sm","type":"password","state":errors.length > 0 ? false : null,"name":"password","placeholder":"Contraseña","autocomplete":"off"},model:{value:(_vm.customer.password),callback:function ($$v) {_vm.$set(_vm.customer, "password", $$v)},expression:"customer.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2824453741)})],1):_vm._e(),(!_vm.edit)?_c('b-form-group',{attrs:{"label":"Repetir Contraseña"}},[_c('validation-provider',{attrs:{"name":"Confirmar Contraseña","rules":"required|min:5|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"size":"sm","type":"password","state":errors.length > 0 ? false : null,"name":"c_password","placeholder":"Confirmar contraseña","autocomplete":"off"},model:{value:(_vm.customer.c_password),callback:function ($$v) {_vm.$set(_vm.customer, "c_password", $$v)},expression:"customer.c_password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,864757716)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"CUIT / DNI"}},[_c('validation-provider',{attrs:{"name":"CUIT / DNI","rules":{
                    required: true,
                    integer: true,
                    min: _vm.customer.type_document === 'dni' ? 8 : 11,
                    max: _vm.customer.type_document === 'dni' ? 8 : 11,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","autocomplete":"dni","placeholder":"00000000","name":"dni","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.dni),callback:function ($$v) {_vm.$set(_vm.customer, "dni", $$v)},expression:"customer.dni"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-form-group',{staticClass:"my-1"},[_c('b-form-radio-group',{attrs:{"options":_vm.documents,"name":"type_document"},on:{"input":_vm.onTypeDocument},model:{value:(_vm.customer.type_document),callback:function ($$v) {_vm.$set(_vm.customer, "type_document", $$v)},expression:"customer.type_document"}})],1)]}}])})],1),_c('b-form-group',{attrs:{"label":"Marca"}},[_c('validation-provider',{attrs:{"name":"Marca","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"brand","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.brand),callback:function ($$v) {_vm.$set(_vm.customer, "brand", $$v)},expression:"customer.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Apellido"}},[_c('validation-provider',{attrs:{"name":"Apellido","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"last_name","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.last_name),callback:function ($$v) {_vm.$set(_vm.customer, "last_name", $$v)},expression:"customer.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Telefono"}},[_c('validation-provider',{attrs:{"name":"Telefono","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"phone","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('h3',{staticClass:"title mt-2"},[_vm._v("DIRECCIÓN DEL CLIENTE")]),_c('b-form-group',{attrs:{"label":"Provincia"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"province","options":_vm.provinces,"state":errors.length > 0 ? false : null},on:{"input":_vm.onProvinces},model:{value:(_vm.customer.direction.province_id),callback:function ($$v) {_vm.$set(_vm.customer.direction, "province_id", $$v)},expression:"customer.direction.province_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Localidad"}},[_c('validation-provider',{attrs:{"name":"Localidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"province","options":_vm.locations,"state":errors.length > 0 ? false : null},model:{value:(_vm.customer.direction.location_id),callback:function ($$v) {_vm.$set(_vm.customer.direction, "location_id", $$v)},expression:"customer.direction.location_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Calle"}},[_c('validation-provider',{attrs:{"name":"Calle","rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","max":"250","name":"street","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.direction.street),callback:function ($$v) {_vm.$set(_vm.customer.direction, "street", $$v)},expression:"customer.direction.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Altura"}},[_c('validation-provider',{attrs:{"name":"Altura","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","max":"15","name":"height","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.direction.height),callback:function ($$v) {_vm.$set(_vm.customer.direction, "height", $$v)},expression:"customer.direction.height"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Codigo Postal"}},[_c('validation-provider',{attrs:{"name":"Codigo Postal","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","max":"30","name":"postal_code","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.direction.postal_code),callback:function ($$v) {_vm.$set(_vm.customer.direction, "postal_code", $$v)},expression:"customer.direction.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nota"}},[_c('validation-provider',{attrs:{"name":"Nota","rules":"max:400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"size":"sm","xlength":"500","name":"note","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.direction.note),callback:function ($$v) {_vm.$set(_vm.customer.direction, "note", $$v)},expression:"customer.direction.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('h3',{staticClass:"title mt-2"},[_vm._v("DATOS DE ENVIO")]),(_vm.isAdmin)?_c('b-form-group',{attrs:{"label":"Tipo Cobro"}},[_c('validation-provider',{attrs:{"name":"Tipo Cobro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"type","options":_vm.typeCustomer,"state":errors.length > 0 ? false : null},on:{"change":_vm.changeTypePay},model:{value:(_vm.customer.type),callback:function ($$v) {_vm.$set(_vm.customer, "type", $$v)},expression:"customer.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4233447183)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Descuento/Incremento"}},[_c('validation-provider',{attrs:{"name":"Descuento/Incremento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"price","label":"text","options":_vm.prices,"state":errors.length > 0 ? false : null},model:{value:(_vm.customer.preference_price_id),callback:function ($$v) {_vm.$set(_vm.customer, "preference_price_id", $$v)},expression:"customer.preference_price_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Servicio de Colecta"}},[_c('validation-provider',{attrs:{"name":"Servicio de Colecta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"collection","text":"text","options":_vm.collections,"state":errors.length > 0 ? false : null},model:{value:(_vm.customer.collection),callback:function ($$v) {_vm.$set(_vm.customer, "collection", $$v)},expression:"customer.collection"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Punto de Dropoff"}},[_c('validation-provider',{attrs:{"name":"Punto de Dropoff","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"size":"sm","name":"point","label":"fullText","options":_vm.points,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"p-1 m-1 border-primary"},[_c('div',[_c('b',[_vm._v("Provincia:")]),_vm._v(" "+_vm._s(option.province)+" ")]),_c('div',[_c('b',[_vm._v("Localidad:")]),_vm._v(" "+_vm._s(option.location)+" ")]),_c('div',[_c('b',[_vm._v("Calle:")]),_vm._v(" "+_vm._s(option.street)+" ")]),_c('div',[_c('b',[_vm._v("Altura:")]),_vm._v(" "+_vm._s(option.height)+" ")])])]}}],null,true),model:{value:(_vm.customer.point),callback:function ($$v) {_vm.$set(_vm.customer, "point", $$v)},expression:"customer.point"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(
                    _vm.customer.point &&
                    _vm.customer.point.longitude &&
                    _vm.customer.point.longitude
                  )?_c('div',[_c('p',{staticClass:"mt-2"},[_c('a',{staticClass:"pl-2",attrs:{"href":_vm.url,"target":"_blank"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"20","icon":"MapIcon"}}),_vm._v(" Ver punto en Google ")],1)])]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Servicio de entrega"}},[_c('validation-provider',{attrs:{"name":"Servicio de entrega","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"retirement","text":"text","options":_vm.retirements,"state":errors.length > 0 ? false : null},model:{value:(_vm.customer.retirement),callback:function ($$v) {_vm.$set(_vm.customer, "retirement", $$v)},expression:"customer.retirement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Canal de venta"}},[_c('validation-provider',{attrs:{"name":"Canal de venta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('multiselect',{attrs:{"size":"sm","name":"channel","text":"name","multiple":true,"label":"name","options":_vm.channels,"state":errors.length > 0 ? false : null},model:{value:(_vm.customer.channels),callback:function ($$v) {_vm.$set(_vm.customer, "channels", $$v)},expression:"customer.channels"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nota"}},[_c('validation-provider',{attrs:{"name":"Nota","rules":"max:400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"size":"sm","xlength":"500","name":"notes","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.notes),callback:function ($$v) {_vm.$set(_vm.customer, "notes", $$v)},expression:"customer.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(
                  _vm.customer.type === 'post-pago' ||
                  _vm.customer.collection == 'client'
                )?_c('b-form-group',{attrs:{"label":"HORA INCIAL DE COLECTA: "}},[_c('b-form-timepicker',{attrs:{"hour12":false},model:{value:(_vm.customer.collection_start_time),callback:function ($$v) {_vm.$set(_vm.customer, "collection_start_time", $$v)},expression:"customer.collection_start_time"}})],1):_vm._e(),(
                  _vm.customer.type === 'post-pago' ||
                  _vm.customer.collection == 'client'
                )?_c('b-form-group',{attrs:{"label":"HORA FINAL DE COLECTA: "}},[_c('b-form-timepicker',{attrs:{"hour12":false},model:{value:(_vm.customer.collection_end_time),callback:function ($$v) {_vm.$set(_vm.customer, "collection_end_time", $$v)},expression:"customer.collection_end_time"}})],1):_vm._e(),_c('h3',{staticClass:"title my-2"},[_vm._v("ZONAS DE ENVÍO")]),_c('b-form-group',[_c('b-form-checkbox-group',{attrs:{"options":_vm.options},model:{value:(_vm.customer.selected),callback:function ($$v) {_vm.$set(_vm.customer, "selected", $$v)},expression:"customer.selected"}})],1),_c('h3',{staticClass:"title my-2"},[_vm._v("SEGUROS PARA TUS ENVIOS")]),_c('b-form-group',{attrs:{"label":"Seguros para tu envíos","description":"Selecciona las áreas en las que deseas asegurar tus envios"}},[_c('b-input-group',[_c('validation-provider',{attrs:{"name":"Zona para seguros"}},[_c('b-form-checkbox-group',{attrs:{"options":_vm.optionsSecured},model:{value:(_vm.customer.is_secured),callback:function ($$v) {_vm.$set(_vm.customer, "is_secured", $$v)},expression:"customer.is_secured"}})],1)],1)],1),_c('h3',{staticClass:"title"},[_vm._v("Datos de Cobro")]),_c('b-form-group',{attrs:{"label":"Límite de Crédito"}},[_c('validation-provider',{attrs:{"name":"Límite de Crédito","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","size":"sm","max":"13","name":"credit_limit","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.credit_limit),callback:function ($$v) {_vm.$set(_vm.customer, "credit_limit", $$v)},expression:"customer.credit_limit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keops User"}},[_c('validation-provider',{attrs:{"name":"Keops User","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","max":"30","name":"keops_id","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.keopsUser.keops_id),callback:function ($$v) {_vm.$set(_vm.customer.keopsUser, "keops_id", $$v)},expression:"customer.keopsUser.keops_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Condición IVA"}},[_c('validation-provider',{attrs:{"name":"Condición IVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"vat_condition","options":_vm.vat_conditions,"state":errors.length > 0 ? false : null},on:{"input":_vm.onVatCondition},model:{value:(_vm.customer.vat_condition),callback:function ($$v) {_vm.$set(_vm.customer, "vat_condition", $$v)},expression:"customer.vat_condition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tipo de Comprobante"}},[_c('validation-provider',{attrs:{"name":"Tipo de Comprobante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"voucher_type","options":_vm.voucher_types,"state":errors.length > 0 ? false : null},model:{value:(_vm.customer.voucher_type),callback:function ($$v) {_vm.$set(_vm.customer, "voucher_type", $$v)},expression:"customer.voucher_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1)]),_c('footer',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"mr-1 float-right",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }