<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <div style="height: 100%">
        <loading :active.sync="isLoading" :is-full-page="false"></loading>

        <h2 class="title px-1">
          DATOS DE TIENDA
          <span style="float: right" v-if="customer && customer.type === 'pre-pago'">
            ({{ customer && customer.advance }}%)
          </span>
        </h2>

        <div class="row justify-content-center px-1">
          <div class="col-sm-12 col-md-12">
            <b-alert
              show
              variant="success"
              style="padding: 5px"
              v-if="customer && customer.keopsUser"
              >Registrado en Keops.</b-alert
            >

            <b-alert
              show
              variant="warning"
              style="padding: 5px"
              v-if="customer && !customer.keopsUser"
              >No registrado en Keops.</b-alert
            >
          </div>
        </div>

        <div v-if="customer != null" class="row justify-content-center">
          <div class="col-sm-12 col-md-12" v-if="view === 'show'">
            <div class="card mx-8" v-if="customer.disabled_at">
              <div class="card-body p-3">
                <h2 v-if="customer.status === 'enabled'" class="title p-1">
                  CUENTA ACTIVA
                </h2>

                <h2 v-else class="title p-1">CUENTA INACTIVA</h2>

                <RowComponent
                  :title="'RAZON: '"
                  :content="customer.reason"
                  v-if="customer.status === 'disabled'"
                />
                <RowComponent
                  :title="'INACTIVA DESDE : '"
                  :content="customer.disabled_at"
                  v-if="customer.status === 'disabled'"
                />
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12" v-if="view === 'show'">
            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <h2 class="title">DATOS BASICOS</h2>
                <RowComponent :title="'TIPO : '" :content="customer.type" />
                <RowComponent :title="'ID: '" :content="customer.id.toString()" />
                <RowComponent :title="'CUIT/DNI: '" :content="customer.dni" />
                <RowComponent :title="'NOMBRE: '" :content="customer.name" />
                <RowComponent :title="'APELLIDO: '" :content="customer.last_name" />
                <RowComponent :title="'EMAIL: '" :content="customer.email" />
                <RowComponent :title="'TELEFONO: '" :content="customer.phone" />
                <RowComponent :title="'MARCA: '" :content="customer.brand" />
                <RowComponent
                  :title="'STATUS: '"
                  :content="customer.status === 'enabled' ? 'Activo' : 'Inactivo'"
                />
                <div>
                  <p>
                    <b>CREADO:</b>
                    <span>{{ customer.created_at | date_format }}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <b>ACTUALIZADO:</b>
                    <span>{{ customer.updated_at | date_format }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12" v-if="view === 'show'">
            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <h2 class="title">DATOS DE ENVIO</h2>
                <RowComponent
                  :title="'APROX. ENVIOS MENSUALES: '"
                  :content="customer.monthly_shipments"
                />
                <RowComponent
                  :title="'SERVICIO DE COLECTA: '"
                  :content="serviceCollection(customer.collection)"
                />

                <RowComponent :title="'PUNTO DE COLECTA: '" :content="pointText" />

                <RowComponent
                  :title="'SERVICIO DE ENTREGA: '"
                  :content="serviceRetirement(customer.retirement)"
                />
                <RowComponent
                  :title="'DESCUENTO/INCREMENTO: '"
                  :content="preferencePrice"
                  v-if="customer.preference_price"
                />
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12" v-if="view === 'show'">
            <div class="card mx-8 p-1" v-if="customer.direction">
              <div class="card-body p-3">
                <h2 class="title">DIRECCION DEL CLIENTE</h2>
                <RowComponent
                  :title="'PROVINCIA: '"
                  :content="customer.direction.location.province.province"
                />
                <RowComponent
                  :title="'LOCALIDAD: '"
                  :content="customer.direction.location.location"
                />
                <RowComponent :title="'CALLE: '" :content="customer.direction.street" />
                <RowComponent :title="'ALTURA: '" :content="customer.direction.height" />
                <RowComponent
                  :title="'CODIGO POSTAL: '"
                  :content="customer.direction.postal_code"
                />
              </div>
            </div>
            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <h2 class="title">CREDENCIALES DE INTEGRACIÓN</h2>
                <div>
                  <p>
                    <b>CANAL DE VENTA:</b>
                    <span
                      v-for="item in customer.channels"
                      v-bind:key="item.id"
                      class="badge badge-primary m-1 p-1"
                      >{{ item.name }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <h2 class="title">ZONAS DE ENVIO</h2>
                <div>
                  <b-form-group>
                    <b-form-checkbox
                      v-for="option in options"
                      v-model="selected"
                      :key="option.value"
                      :value="option.value"
                      :disabled="true"
                      name="flavour-3a"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <h2 class="title">OTROS DATOS</h2>

                <RowComponent
                  :title="'KEOPS USER: '"
                  :content="customer.keopsUser.keops_id"
                  v-if="customer.keopsUser"
                />

                <RowComponent
                  :title="'CONDICION IVA: '"
                  :content="customer.vat_condition"
                />
                <RowComponent
                  :title="'TIPO DE COMPROBANTE: '"
                  :content="customer.voucher_type"
                />

                <RowComponent
                  :title="'DEUDA: '"
                  :content="customer.amount_debt || 0"
                  :type="'amount'"
                />

                <RowComponent
                  v-if="customer.type === 'post-pago'"
                  :title="'LIMITE DE CREDITO: '"
                  :content="customer.credit_limit || 0"
                  :type="'amount'"
                />
                <RowComponent
                  :title="'CANTIDAD DE TRANSACCIONES: '"
                  :content="customer.transactions_count"
                  :type="'number'"
                />
                <RowComponent
                  v-if="customer.type === 'post-pago'"
                  :title="'HORA INCIAL DE COLECTA: '"
                  :content="customer.collection_start_time"
                />
                <RowComponent
                  v-if="customer.type === 'post-pago'"
                  :title="'HORA FINAL DE COLECTA: '"
                  :content="customer.collection_end_time"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12" v-else>
            <user-change-password
              :user="customer"
              @onClose="onCloseChange"
            ></user-change-password>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 mt-1 float-right"
            variant="outline-primary"
            @click="onClose"
            >Cerrar</b-button
          >
          <b-button
            v-if="view === 'show' && customer && customer.id !== 1 && isAdminOrStaff"
            size="sm"
            class="mr-1 mt-1 float-right"
            variant="info"
            @click="onCreateOrUpdateKeops"
          >
            <span v-if="customer && !customer.keopsUser">Registrar en Keops</span>
            <span v-if="customer && customer.keopsUser">Actualizar Keops</span>
          </b-button>

          <b-button
            v-if="view === 'show' && customer && customer.id !== 1 && isAdminOrStaff"
            size="sm"
            class="mr-1 mt-1 float-right"
            variant="primary"
            @click="onVerification"
            >Email de verificación</b-button
          >
          <b-button
            v-if="view === 'show' && customer && customer.id !== 1 && isAdminOrStaff"
            size="sm"
            class="mr-1 mt-1 float-right"
            variant="danger"
            @click="onChange"
            >Cambiar Contraseña</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import UtilService from "@/services/UtilService";
import AlertService from "@/services/AlertService";

import UserChangePassword from "../users/UserChangePassword";

import axios from "axios";

export default {
  name: "CustomerView",
  props: {
    item: Object,
  },
  components: {
    UserChangePassword,
  },
  data() {
    return {
      isLoading: false,
      customer: null,
      view: "show",
      isAdmin: UserService.isAdmin(),
      isAdminOrStaff: UserService.isAdminOrStaff(),
      selected: [], // Must be an array reference!
      options: [
        {
          text: "Puede generar envios a todos los CP de Capital Federal",
          value: "CABA",
          disabled: true,
        },
        {
          text: "Puede generar envios a todos los CP de GBA",
          value: "GBA",
          disabled: true,
        },
        {
          text: "Puede generar envios a todos los CP de La Plata",
          value: "PLATA",
          disabled: true,
        },
        {
          text: "Puede generar envios a todos los CP del Interior",
          value: "INTERIOR",
          disabled: true,
        },
      ],
    };
  },
  mounted() {
    this.show();
  },
  beforeDestroy() {
    if (this.source) {
      this.source.source.cancel("Operation canceled by the user.");
    }
  },
  methods: {
    async onVerification() {
      const result = await AlertService.question(
        "¿Estás seguro?",
        "Estas seguro de querer Reenviar email de verificación "
      );
      if (result.value !== undefined) {
        this.isLoading = true;
        CustomerService.reverify(this.customer.id)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Información`,
          text: `Copiado correctamente`,
          icon: "InfoIcon",
          variant: "info",
        },
      });
    },
    onChange() {
      this.view = "change";
    },
    onCloseChange($event) {
      this.view = "show";
      if ($event.change) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Actualizar`,
            text: `Contraseña actualizada correctamente`,
            icon: "InfoIcon",
            variant: "info",
          },
        });
      }
    },
    serviceCollection(data) {
      return UtilService.serviceCollection(data);
    },
    serviceRetirement(data) {
      return UtilService.serviceRetirement(data);
    },
    onClose() {
      this.$emit("close");
    },
    show() {
      this.isLoading = true;
      CustomerService.show(this.item.id)
        .then((response) => {
          this.isLoading = false;
          if (axios.isCancel(response)) {
            console.log("Request canceled", response.message);
          } else {
            const { data } = response;
            this.customer = data.data;
            this.selected = this.customer.shipping_areas.map((d) =>
              d.is_active ? d.zone : null
            );
          }
        })
        .catch((thrown) => {
          this.isLoading = false;
          this.$emit("close");
          console.error(thrown);
        });
    },

    /**
     *
     */
    async onCreateOrUpdateKeops() {
      const questions = this.customer.keopsUser
        ? "Estas seguro de querer Actualizar la información de la tienda en Keops ? "
        : "Estas seguro de querer registrar en Keops ? ";
      const result = await AlertService.question("¿Estás seguro?", questions);
      if (result.value !== undefined) {
        this.isLoading = true;
        CustomerService.registerUpdateKeops(this.customer)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.show();
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
            this.$bvToast.toast(
              "ocurrio un error al intentar registrar o actualizar en keops",
              {
                title: "keops",
                autoHideDelay: 3000,
                variant: "error",
                appendToast: false,
              }
            );
          });
      }
    },
  },
  computed: {
    /**
     *
     */
    pointText: function () {
      if (this.customer.point) {
        const item = this.customer.point;
        return [
          item.province ? item.province : item.location.province.province,
          typeof item.location === "string" ? item.location : item.location.location,
          item.street,
          item.height,
        ]
          .filter((i) => i)
          .join(", ");
      }
      return "";
    },

    /**
     *
     */
    preferencePrice: function () {
      const value = this.customer.preference_price.percentage;
      let text = "";
      if (value > 0) {
        text = "INCREMENTO";
      }
      if (value < 0) {
        text = "DESCUENTO";
      }

      if (value === 0) {
        text = "SIN DESCUENTO";
      }
      return ` ${text} ${Math.abs(value)} %`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.custom-control-label {
  color: #161d31 !important;
}
</style>
